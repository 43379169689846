import BasicSection from "components/BasicSection";
import * as React from "react";
import Layout from "components/Layout";
import { PageProps } from "gatsby";
import styled, { css } from "styled-components";
import {
    type CommunityIcon,
    communityIcons,
    ArrowUpRightSVG,
} from "images/icons/community-icons";
import ExternalLink from "components/links/ExternalLink";
import { useIsDarkMode } from "hooks/use-media-query";

const Community: React.FC<PageProps> = ({ path }) => {
    return (
        <Layout title="Ente - Community" path={path}>
            <Heading />
            <Description />
            <PrimaryCards />
            <OtherCards />
        </Layout>
    );
};

export default Community;

const Heading: React.FC = () => {
    return (
        <BasicSection.Header>
            <BasicSection.Heading>Community</BasicSection.Heading>
        </BasicSection.Header>
    );
};

const Description: React.FC = () => {
    return (
        <BasicSection.Body style={{ textAlign: "center" }}>
            We are building Ente in the open, with our customers. <br />
            <div className="mt-3">
                Having this journey with everyone is not just our secret sauce -
                it is just much more fun this way!
            </div>
        </BasicSection.Body>
    );
};

// PrimaryCards and its child components

const PrimaryCards: React.FC = () => {
    // Mapping over first 3 items to render Discord, Figma and Github cards.
    const initialCommunities = communityIcons.slice(0, 3);
    const cardText1Content = [
        "Join the Discussion",
        "Explore the Design",
        "Fork the Code",
    ];
    return (
        <div className="d-flex justify-content-center">
            <CardsContainer>
                {initialCommunities.map((communityInfo, index) => (
                    <PrimaryCard key={index} {...communityInfo}>
                        {cardText1Content[index]}
                    </PrimaryCard>
                ))}
            </CardsContainer>
        </div>
    );
};

const PrimaryCard: React.FC<React.PropsWithChildren<CommunityIcon>> = ({
    id,
    icon,
    url,
    hoverBackground,
    shouldInvertInDarkMode,
    children,
}) => {
    /**
     * Toggling invertIconColor to true in dark mode for specific icons only
     * Similar done in CommunityCard Component
     */
    const isDarkMode = useIsDarkMode();
    const invertIconColor = shouldInvertInDarkMode
        ? isDarkMode && shouldInvertInDarkMode
        : false;

    const isDiscordCard = id === "Discord" ? true : false;
    return (
        <ExternalLink href={url} style={{ textDecoration: "none" }}>
            <CardComponent
                $hoverBackground={hoverBackground}
                $isDiscordCard={isDiscordCard}
            >
                <CardText1>{children}</CardText1>
                <CardLogo $invertColor={invertIconColor}>{icon}</CardLogo>
                <CardText2>
                    <div className="me-2">{id}</div>
                    {ArrowUpRightSVG}
                </CardText2>
            </CardComponent>
        </ExternalLink>
    );
};

const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 440px;
    margin-block-start: 6%;
    width: 1100px;
    @media (max-width: 1132px) {
        margin-inline: 6%;
        height: 360px;
    }
    @media (max-width: 992px) {
        margin-inline: 6%;
        height: 300px;
    }
    @media (max-width: 768px) {
        height: 228px;
    }
    @media (max-width: 576px) {
        flex-direction: column;
        align-items: center;
        height: 1500px;
        margin-block-start: 10%;
    }
    @media (max-width: 420px) {
        height: 1350px;
    }
    @media (max-width: 326px) {
        height: 1100px;
    }
    @media (max-width: 280px) {
        height: 900px;
    }
`;

const CardText1 = styled.div`
    z-index: 2;
    font-size: 22px;
    @media (max-width: 992px) {
        font-size: 17px;
    }
    @media (max-width: 576px) {
        font-size: 18px;
    }
    @media (max-width: 326px) {
        font-size: 16px;
    }
`;

const CardText2 = styled.div`
    z-index: 2;
    display: flex;
    height: 28px;
    font-size: 24px;
    svg {
        height: 128%;
        width: 128%;
    }

    @media (prefers-color-scheme: dark) {
        svg {
            filter: invert(100%);
        }
    }

    @media (max-width: 992px) {
        height: 24px;
        font-size: 20px;
        svg {
            margin-block-start: 4%;
            height: 100%;
            width: 100%;
        }
    }
    @media (max-width: 576px) {
        height: 28px;
        font-size: 24px;
    }
    @media (max-width: 326px) {
        height: 24px;
        font-size: 20px;
    }
`;

interface CardLogoProps {
    $invertColor: boolean;
}

const CardLogo = styled.div<CardLogoProps>`
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(180deg, #e8e8e8 0%, #f6f6f6 100%);
    height: 27%;
    width: 36%;
    border-radius: 10%;
    transition: 200ms ease-in-out;

    @media (prefers-color-scheme: dark) {
        background: linear-gradient(180deg, #1b1b1b 0%, #000 100%);
    }

    svg {
        width: 65%;
        height: 65%;
        z-index: 2;
        ${(props) =>
            props.$invertColor &&
            css`
                filter: invert(100%);
            `}
    }
`;

interface CardComponentProps {
    $hoverBackground: string;
    $isDiscordCard: boolean;
}

const CardComponent = styled.div<CardComponentProps>`
    position: relative;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    flex-direction: column;
    align-items: center;
    width: 330px;
    border-radius: 5%;
    background: radial-gradient(
        farthest-corner at 1% 1%,
        rgba(253, 253, 253, 0.85) 0%,
        rgba(236, 236, 236, 0.85) 100%
    );
    transition:
        box-shadow 200ms ease-in-out,
        border-radius 200ms ease-in-out;

    @media (prefers-color-scheme: dark) {
        background: linear-gradient(
            100deg,
            rgba(35, 35, 35, 0.25) 0%,
            rgba(0, 0, 0, 0.3) 100%
        );
    }

    /**
     * Transition effects are not directly applied to "background" in CSS
     * Therefore, toggling opacity of a pseudo-element "&:after" upon hover
     * Similar is done for "OtherCardLogo" component
     */

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: ${(props) => props.$hoverBackground};
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
        box-shadow:
            3px 0px 6px 0px rgba(0, 0, 0, 0.24),
            10px 2px 10px 0px rgba(0, 0, 0, 0.2);
        border-radius: 13% 13% 13% 13% / 10% 10% 10% 10%;
        ${(props) =>
            props.$isDiscordCard
                ? css`
                      ${CardText1}, ${CardText2} {
                          color: white;
                          svg {
                              filter: invert(100%);
                          }
                      }
                  `
                : css`
                      ${CardText1}, ${CardText2} {
                          color: black;
                          svg {
                              filter: invert(0%);
                          }
                      }
                  `}
    }

    @media (max-width: 1132px) {
        width: 270px;
    }

    @media (max-width: 992px) {
        width: 225px;
    }

    @media (max-width: 768px) {
        width: 171px;
    }

    @media (max-width: 576px) {
        height: 480px;
        width: 360px;
    }

    @media (max-width: 420px) {
        height: 420px;
        width: 315px;
    }

    @media (max-width: 326px) {
        height: 340px;
        width: 275px;
    }

    @media (max-width: 280px) {
        height: 280px;
        width: 210px;
    }
`;

// OtherCards and its child components

const OtherCards: React.FC = () => {
    // We have specially styled cards for the first three communities. For the
    // rest, we use a generic card by mapping over the remaining ones.
    const remainingCommunities = communityIcons.slice(3);

    return (
        <div className="d-flex justify-content-center">
            <OtherCards_>
                {remainingCommunities.map((community, index) => (
                    <CommunityCard key={index} {...community} />
                ))}
            </OtherCards_>
        </div>
    );
};

const OtherCards_ = styled.div`
    display: flex;
    justify-content: space-between;
    height: 120px;
    margin-block-start: 10%;
    width: 1100px;
    @media (max-width: 1132px) {
        margin-inline: 6%;
    }
    @media (max-width: 992px) {
        height: 100px;
    }
    @media (max-width: 768px) {
        height: 80px;
    }
    @media (max-width: 576px) {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-inline: auto;
        margin-block-end: 40%;
        width: 360px;
        gap: 1rem;
    }
    @media (max-width: 420px) {
        width: 315px;
    }
    @media (max-width: 326px) {
        width: 275px;
        gap: 0.8rem;
    }
    @media (max-width: 280px) {
        width: 210px;
    }
`;

const CommunityCard: React.FC<CommunityIcon> = ({
    id,
    icon,
    url,
    hoverBackground,
    shouldInvertInDarkMode,
}) => {
    const isDarkMode = useIsDarkMode();
    const invertIconColor = shouldInvertInDarkMode
        ? isDarkMode && shouldInvertInDarkMode
        : false;
    return (
        <ExternalLink href={url}>
            <OtherCardLogo
                $hoverBackground={hoverBackground}
                $invertColor={invertIconColor}
            >
                {icon}
            </OtherCardLogo>
        </ExternalLink>
    );
};

interface OtherCardLogoProps {
    $hoverBackground: string;
}

const OtherCardLogo = styled(CardLogo)<OtherCardLogoProps>`
    position: relative;
    height: 100%;
    width: auto;
    aspect-ratio: 1;
    transition: box-shadow 200ms ease-in-out;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: ${(props) => props.$hoverBackground};
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
        box-shadow: 3.145px 0px 6.291px 0px rgba(0, 0, 0, 0.34);
    }

    @media (prefers-color-scheme: dark) {
        border-radius: 10%;
        &:hover {
            svg {
                ${(props) =>
                    props.$invertColor &&
                    css`
                        filter: invert(0%);
                    `}
            }
        }
    }

    @media (max-width: 576px) {
        height: 100px;
    }
    @media (max-width: 420px) {
        height: 80px;
    }
    @media (max-width: 326px) {
        height: 70px;
    }
    @media (max-width: 280px) {
        height: 60px;
    }
`;
