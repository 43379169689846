import React, { HTMLAttributes, PropsWithChildren } from "react";

/**
 * A basic, relatively unstyled, top level section/container for holding the
 * body content of a page.
 *
 * This provides a basic container styling that fits with the rest of the
 * header/footer added by the default Layout. Not every page has to use it, but
 * it is a good (and consistent) start if there is nothing specific to tweak.
 *
 * Use it like this:
 *
 *     <BasicSection>
 *         <BasicSection.Header>
 *             <BasicSection.Heading>...</BasicSection.Heading>
 *         </BasicSection.Header>
 *         ...
 *     </BasicSection>
 *
 * For more specific cases, more specific components like `Article` can be used.
 */
const BasicSection: React.FC<
    PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = (props) => {
    const { children, ...rest } = props;
    return <section {...rest}>{children}</section>;
};

/**
 * A page header that goes well with BasicSection.
 */
const BSHeader: React.FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = (
    props,
) => {
    const { className, children, ...rest } = props;
    return (
        <header
            className={`max-w-lg mx-auto mt-2 mb-5 px-3 px-sm-5 ${
                className ?? ""
            }`}
            {...rest}
        >
            {children}
        </header>
    );
};

/**
 * The heading for holding the title of the page.
 *
 * Goes well with BasicSection.
 */
const BSHeading: React.FC<
    PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>
> = (props) => {
    const { className, children, ...rest } = props;
    return (
        <h1
            className={`mt-3 text-center text-title ${className ?? ""}`}
            {...rest}
        >
            {children}
        </h1>
    );
};

/**
 * A container for body ("main") content that goes well with a BasicSection.
 * This is suitable for as a container for pages that do their own styling - it
 * provides just margins matching the BasicSection.Header.
 *
 * Pass a `className` to replace the default "max-w-lg" with one (or more)
 * custom classes.
 *
 * @see BasicSection.BodyElevated for a variant that has an elevated background.
 */
const BSBody: React.FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = (
    props,
) => {
    const { className, children, ...rest } = props;
    return (
        <div
            className={`mx-auto px-3 px-sm-5 text-break ${
                className ?? "max-w-lg"
            }`}
            {...rest}
        >
            {children}
        </div>
    );
};

/**
 * A container for body ("main") content that goes well with a BasicSection. It
 * provides an elevated background, and is thus suitable for pages that need to
 * display some textual content (but are not purely textual – those should use
 * the `Article` classes).
 *
 * The width of this is slightly larger than that of an Article.Body. An
 * Article.Body is intended for large text sections, and so has a smaller width
 * to improve readability.
 */
const BSBodyElevated: React.FC<
    PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = (props) => {
    const { className, children, ...rest } = props;
    return (
        <div
            className={`max-w-lg mx-auto bg-elevated rounded-3 px-3 py-3 px-md-5 py-md-4 text-break ${
                className ?? ""
            }`}
            {...rest}
        >
            <div className="my-h">{children}</div>
        </div>
    );
};

export default Object.assign(BasicSection, {
    Header: BSHeader,
    Heading: BSHeading,
    Body: BSBody,
    BodyElevated: BSBodyElevated,
});
